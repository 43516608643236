/* requires:
polyfill.js
*/

// for active menu highlighting
(function () {
  const a = document.getElementById('nav').getElementsByTagName('a');
  // let loc;
  // if (window.location.href.substr(location.href.length - 1, 1) == '/') {
  //   loc = window.location.href + 'index.html';
  // } else {
  //   loc = window.location.href;
  // }
  let loc = window.location.href;
  for (var i = 0; i < a.length; i++) {
    if (a[i].href == loc) {
      a[i].classList.add('is-active');
    }
  }
})();

document
  .getElementById('nav')
  .querySelector('ul')
  .insertAdjacentHTML('beforebegin', "<span id='menutoggle'><span>Menu</span></span>");
const menuToggle = document.getElementById('menutoggle');
const subMenuToggle = document.getElementById('menutoggle');
const sideNav = document.getElementById('sideNav');
const overlay = document.getElementById('overlay');
const activeClass = 'is-active';

function openMenu() {
  sideNav.classList.toggle(activeClass);
  overlay.classList.toggle(activeClass);
}

menuToggle.onclick = function (event) {
  openMenu();
  event.preventDefault();
};

for (let sideNav of document.querySelectorAll('#sideNav span.submenu')) {
  sideNav.addEventListener('click', function () {
    if (menuToggle.offsetWidth > 0 && menuToggle.offsetHeight > 0) {
      // if the #menuToggle is visible
      sideNav.classList.toggle(activeClass);
    }
  });
}

for (let submenuToggle of document.querySelectorAll('#nav span.submenu, #nav a.submenu')) {
  submenuToggle.addEventListener('click', function () {
    // if the #menuToggle is visible
    submenuToggle.classList.toggle(activeClass);
  });
}

function hideMenu() {
  var el = document.querySelectorAll('#sideNav, #sideNav span.submenu');
  var i;
  for (i = 0; i < el.length; i++) {
    sideNav.classList.remove(activeClass);
  }
  overlay.classList.remove(activeClass);
}

document.getElementById('closebtn').onclick = function (e) {
  hideMenu();
};

document.addEventListener(
  'click',
  function (e) {
    if (sideNav.offsetWidth > 0 && sideNav.offsetHeight > 0) {
      // if the #menuToggle is visible
      var e = e ? e : window.event;
      var eventElement = e.target;
      if (!eventElement.closest('#sideNav')) {
        if (sideNav.classList.contains(activeClass)) {
          hideMenu();
        }
      }
    }
  },
  false
);

var resizeTimer;
window.addEventListener(
  'resize',
  function () {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
      if (menuToggle.offsetWidth <= 0 && menuToggle.offsetHeight <= 0) {
        // if the #menuToggle is hidden
        hideMenu();
      }
    }, 250);
  },
  false
);
